import 'core-js';
import React, { useState } from 'react';
import './App.css';
import "antd/dist/antd.less";
import Hero from './components/Hero';
import IntroText from './components/IntroText';
import Success from './components/Success';
import Blended from './components/Blended';
import WindowDimensionsProvider from './components/Utility/WindowDimensionsProvider';
import Footer from './components/Footer';
import Return from './components/Return';
import Tutores from './components/Tutores';
import HowWeLearn from './components/HowWeLearn';
import Contact from './components/Contact';
import Courses from './components/Courses';
import Desarrollo from './components/Desarrollo';
import Value from './components/Value';
import { Menu  } from 'antd';


const App = (props) => {

  const pages = {
    about: {
      id: 0,
      title: '¿Quiénes somos?',
      component: <div>
        <IntroText/>
        <Success/>
        <br/>
        <Tutores/>
        <Blended/>
        <HowWeLearn/>
      </div>
    },
    courses: {
      id: 1,
      title: 'Catálogo',
      component: <div>
        <Courses/>
        <Desarrollo/>
          <div style={{height:'10vh'}}></div>
      </div>
    },
    value: {
      id: 2,
      title: 'Nuestro valor',
      component: <div>
        <Value/>
      </div>
    },
    contact: {
      id: 3,
      title: 'Contacto',
      component: <div >
        <Contact/>
      </div>
    },
  }

  const [currentPage, setCurrentPage] = useState('about')

  return (
    <WindowDimensionsProvider>
      <div className="App">
        <header id="header-wrap">
          <Hero />
        </header>
        <div>

          <Navigation 
            setCurrentPage={setCurrentPage} 
            currentPage={currentPage} 
            pages={pages}
          />
          {pages[currentPage].component}
          <Return/>
        </div>
        <footer>
          <Footer/>
        </footer>
      </div>
    </WindowDimensionsProvider>
    )
}

const Navigation = ({setCurrentPage, currentPage, pages}) => {
  return (
    <Menu 
      selectedKeys={[currentPage]}
      mode="horizontal"
      className="navigation">
      {Object.entries(pages).map((p,i) => (
        <Menu.Item key={p[0]} onClick={(e) => setCurrentPage(p[0])}>
            {p[1].title}
        </Menu.Item>
      ))}
    </Menu>
  )
}

export default App;
