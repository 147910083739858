import React from 'react';
import { useWindowDimensions } from '../Utility/WindowDimensionsProvider';
// import LazyHero from 'react-lazy-hero';
import MoodleForm from '../MoodleForm';
import heroImage from './splash.jpg';
import logo from './logo.png';
import { Row, Col } from 'antd';

const Hero = () => {

  const breakpoint = 740;
  const { width } = useWindowDimensions()

  return (width > breakpoint ? renderDesktop() : renderMobile())
}
        // color="rgba(0,0,0,0.5)"
        // minHeight="70vh"

const renderMobile = () => {
  return (
    <div>
      <div className="hero-img-container">
      <img 
        style={{
          minHeight:'70vh',
          opacity:0.3,
        }}
        className="hero-container"
        src={heroImage}
        alt="campuschile"
      />
      </div>
      <div className="spacer"/>
      <div >
        <Row>
          <Col lg={16} md={24}>
            <Row>
              <Col lg={24} md={12}>
                <img src={logo} alt="CampusChile" style={{maxWidth:200, marginBottom:36}}/>
              </Col>
            </Row>
          </Col>
          <Col lg={8} md={24}>
            <MoodleForm/>
          </Col>
        </Row>
    </div>
      <div className="spacer"/>
    </div>
  );
}

const renderDesktop = () => {
  return (
    <div>
      <div className="hero-img-container">
      <img 
        style={{
          minHeight:'70vh',
          left:0,
        }}
        className="hero-container"
        src={heroImage}
        alt="campuschile"
      />
      </div>
      <div className="spacer"/>
      <div >
        <Row>
          <Col lg={16} md={24}>
            <Row>
              <Col lg={24} md={12}>
                <img src={logo} alt="CampusChile" style={{maxWidth:400, marginBottom:36}}/>
              </Col>
              <Col lg={24} md={12}>
                <div>
                  <span style={{fontWeight:'400',color:'rgba(255,255,255,0.9)',fontSize:34,textAlign:'left',display:'inline-block'}}>
                    LEO Y OLVIDO,<br/>VEO Y RECUERDO,<br/>HAGO Y  APRENDO</span><br/>
                  <span style={{fontStyle:'italic',fontWeight:'400',color:'rgba(255,255,255,0.9)',fontSize:20,textAlign:'left',display:'inline-block'}}>
                  Confucio</span>
                </div>
              </Col>
            </Row>
          </Col>
          <Col lg={8} md={24}>
            <MoodleForm/>
          </Col>
        </Row>
    </div>
      <div className="spacer"/>
    </div>
  )
}

export default Hero

