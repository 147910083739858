import React from 'react';
import './Footer.css'
import { Row, Col } from 'antd';

const Footer = (props) => {
  return (
    <div className="footer">
      <Row>
        <Col lg={12} md={24}>
          <span className="footer-list-item-title">
            {"Dirección: "}
          </span>
          <span className="footer-list-item-content">
            Av. Manquehue Norte 151 of. 207
          </span>
        </Col>
        <Col lg={12} md={24}>
          <span className="footer-list-item-title" style={{marginLeft:50}}>
            {"Teléfono: "}
          </span>
          <span className="footer-list-item-content">
            (+56) 2 32239149
          </span>
        </Col>
      </Row>
    </div>
    )
}
export default Footer;
      // <ul className="footer-list">
        // <li className="footer-list-item">
        // </li>
        // <li className="footer-list-item">
        // </li>
      // </ul>
