import React from 'react';
import hands from './hands.jpg';
import logo from './campuschile-white-logo.png';
import triangle from './triangle.png';
import { Row, Col } from 'antd';
import { Parallax } from 'react-parallax';
import { useWindowDimensions } from '../Utility/WindowDimensionsProvider';

const Success = (props) => {

  const breakpoint = 740;
  const { width } = useWindowDimensions()
  const mobile = width < breakpoint;

  return (
    <Row>
      <Col span={24}>
        <Parallax
          bgImage={hands}
          className="hands-parallax"
          bgImageAlt="manos"
          strength={100}
        >
          <div style={{
            position:'absolute',
            transformOrigin:'0',
            transform:mobile ? 'scale(0.5)' : 'scale(1)',
            top:'10%',
            left:'0',
            }}>
            <div style={{
              backgroundColor:'#96bf0e',
              display:'inline-block',
              width:40,
              height:92,
              marginRight:10,
              transform:'translateY(2px)',
              }}></div>
            <div style={{
              display:'inline-block'
              }}>
              <div style={{
                width:200,
                color: '#96bf0e',
                fontWeight:'600', 
                borderBottom:'2px dashed #96bf0e',
                fontSize:50,
                lineHeight:'50px',
                }}>CLAVES</div>
              <div style={{
                width:200, 
                color: '#2178b1',
                fontSize:39,
                paddingTop:6,
                lineHeight:'41px',
                fontWeight:'600',
                }}>DEL ÉXITO</div>
            </div>
          </div>

          <img src={triangle} alt="" 
            style={{
            width:mobile ? '80%' : 400,
            marginTop:'10vh',
            marginBottom:'10vh'
            }}/>
          <img src={logo} alt="" 
            style={{
              height:mobile ? 40 : 70,
              position:'absolute',
              top:70,
              right:0
            }}/>
        </Parallax>
      </Col>
    </Row>
  )
}

export default Success;
