import React from 'react';
import { useWindowDimensions } from '../Utility/WindowDimensionsProvider';
import { Row, Col, Card } from 'antd';
import learnByDoing from './learnByDoing.jpg';
import tutores from './customer-service.svg';

const Tutores = (props) => {
  const breakpoint = 740;
  const { width } = useWindowDimensions()
  const mobile = width < breakpoint;

  return (
  <div>
    <Row style={{marginTop:54}}>
      <div className="intro-title" style={{fontSize:mobile ? 30 : 54}}>
        Acompañamiento tutorial siempre</div>
      <br/>
      <Col lg={{span:5,offset:3}} md={{span:24}} >
        <div style={{textAlign:'center',marginTop:40}}>
          <img alt="" src={tutores} style={{width:150,display:'inline-block'}} className="soft-shadow"/>
        </div>
      </Col>
      <Col lg={13} md={24}>
          <Card  style={{border:'none'}}>
          <p style={{fontSize:18,textAlign:'justify'}}>
            En CampusChile nos interesa que los resultados de su trabajo sean el reflejo de lo aprendido-haciendo , es por esto que ponemos a su disposición el primer Sistema de Tutores Expertos (STE) en Chile, los que le asistirán en cada etapa del aprendizaje: guiando, motivando y resolviendo sus dudas técnicas y de contenidos.
            <br/>
            La cantidad de tutores por alumno varia de acuerdo al programa, desde 30 alumnos por Tutor.
          </p>
        </Card>
      </Col>
    </Row>
    <Row style={{marginTop:54}}>
      <div className="intro-title" style={{fontSize:mobile ? 30 : 54}}>
        Aprende Haciendo, No Viendo</div>
      <Col md={24} lg={{span:10,offset:3}} >
        <img src={learnByDoing} alt="" width="100%" className="soft-shadow"
          style={{display:'block',marginTop:30}}
        />
      </Col>
      <Col md={24} lg={{span:8, offset:1}}>
        <Card  style={{border:'none'}}>
          <p style={{fontSize:18,textAlign:'justify'}}>
            Nuestra metodología “Aprende Haciendo” (Learn by Doing) le enseña a utilizar las herramientas aprendidas para aplicarlas en su trabajo.
            Somos el aliado de su área de Desarrollo Organizacional en el análisis, propuesta, ejecución y evaluación de programas de educación corporativa.
            <br/>
            <br/>
            Nuestras alianzas con líderes mundiales en el sector educativo generan mejoras medibles en la gestión de los participantes de su empresa.
          </p>
        </Card>
      </Col>
    </Row>
    <br/>
  </div>
    )
}
export default Tutores;
