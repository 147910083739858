import React from 'react';
import { Row, Col, Card } from 'antd';

const IntroText = (props) => {
  return (
    <Row gutter={{md:8,lg:0}}>
      <Col md={24} lg={{span:9,offset:2}} >
        <Card className="intro-card">
          <h1>Quienes Somos</h1>
          <main>
            <p>CampusChile es el resultado de experiencia y capacidad en el área
              de desarrollo de competencias y capacitación, gracias a la aplicación
              de mejores prácticas de nuestros asociados a nivel mundial.
              <br/>
              <br/>
              Más de 5 años de experiencia y 5.000 profesionales de todas
              las áreas han desarrollado sus competencias gracias al modelo
              <b> “Learn by Doing”</b> y al Sistema de Tutores Expertos “STE”.
            </p>
          </main>
        </Card>
      </Col>
      <Col md={24} lg={{span:9,offset:2}} >
        <Card className="intro-card">
          <h1>Nuestra Filosofía</h1>
          <p>Creemos en la formación y crecimiento de las personas. Todos nuestros
            procesos y recursos tecnológicos están enfocados hacia el
            mejoramiento continuo y capacitación a lo largo de la vida, porque
            nunca dejamos de aprender. 
            <br/>
            <br/>
            Nuestro objetivo de calidad nos motiva
            a trabajar con un grupo segmentado de empresas, lo que nos permite
            garantizar resultados óptimos, medibles y de largo plazo.
          </p>
        </Card>
      </Col>
    </Row>
    )
}
export default IntroText;
