// #96bf0e #2178b1
import React from 'react';
import { useWindowDimensions } from '../Utility/WindowDimensionsProvider';
import { Row, Col } from 'antd';
import stairs from './stairs.png';
import planning from './planning.jpg';
import _interface from './interface.png';
import anywhere from './anywhere.jpg';
import { Parallax } from 'react-parallax';
import './value.css';

const Value = (props) => {
  return (
    <div>
      <br/>
      <LearnByDoing/>
      <Anywhere/>
      <Interface2/ >
      <Asesoramos/ >
        <br/>
      <Desarrollo/ >

    </div>
  );
}

export default Value

const Anywhere = () => {
  const breakpoint = 740;
  const { width } = useWindowDimensions()
  const mobile = width < breakpoint;
  return (
    <div>
    <div style={{marginTop:64, color:'white',backgroundColor:'rgba(0,0,0,0.4)'}}>
        <Parallax
          bgImage={anywhere}
          className="anywhere-parallax"
          bgImageAlt=""
          strength={600}
          renderLayer={percentage => (
            <div
              style={{
                background: `rgba(0, 0, 0, ${percentage * 1})`,
                left: 0,
                position: 'absolute',
                top: 0,
                width:'100%',
                height:'100%',
              }}>
              <p style={{
                left: mobile ? 0 : '20%',
                position: 'absolute',
                top: mobile ? '40%' : '50%',
                width: mobile ? '100%' : '60%',
                padding: mobile ? 20 : 0,
                lineHeight:'40px',
                fontSize:22
                }}>
              Nuestros cursos a distancia te permiten realizar tus cursos en una tablet, donde quieras y cuando puedas. Nuestros cursos no necesitan una conexión a internet, la tablet la incluímos en tu curso.
            </p>
          </div>
          )}
        >

      <Row style={{marginTop:mobile ? 40 : 72}}>
        <Col lg={{span:16,offset:4}} md={{span:24}}>
          <h1 className="intro-title" style={{fontSize:mobile ? 40 : 54,color:'white'}}>
            Estudia donde y cuando quieras
          </h1>
        </Col>
      </Row>

      <div style={{height:mobile ? '60vh' : '40vh'}}/>
      </Parallax>

    </div>

    </div>
  );
}


const Interface2 = () => {
  const breakpoint = 740;
  const { width } = useWindowDimensions()
  const mobile = width < breakpoint;
  return (
    <div>
    <div style={{marginTop:48, color:'white',backgroundColor:'rgba(0,0,0,0.4)'}}>
        <Parallax
          bgImage={_interface}
          className="interface-parallax"
          bgImageAlt=""
          strength={600}
          renderLayer={percentage => (
            <div
              style={{
                background: `rgba(0, 0, 0, ${percentage * 1})`,
                left: 0,
                position: 'absolute',
                top: 0,
                width:'100%',
                height:'100%',
              }}>
              <div style={{
                left: mobile ? 0 : '20%',
                position: 'absolute',
                top: mobile ? '35%' : '50%',
                width: mobile ? '100%' : '60%',
                padding: mobile ? 20 : 0,
                lineHeight:'40px',
                fontSize:22
                }}>
                <p style={{fontSize:18}} >
                  Usamos las últimas tecnologías para crear interfaces rápidas, responsivas e intuitivas.
                </p>
                <p style={{fontSize:22, color:'#96bf0e'}} >
                  "El diseño no es solo la apariencia, el diseño es cómo funciona”
                </p>
                <p style={{fontSize:18}} >
                  Nos preocupamos de que nuestros cursos sean intuitivos y funcionales.
                </p>
                <div>
          </div>
            </div>
          </div>
          )}
        >

      <Row style={{marginTop:mobile ? 40 : 72}}>
        <Col lg={{span:16,offset:4}} md={{span:24}}>
          <h1 className="intro-title" style={{fontSize:mobile ? 40 : 54,color:'white'}}>
            Interfaz amigable
          </h1>
        </Col>
      </Row>

      <div style={{height:mobile ? '60vh' : '40vh'}}/>
      </Parallax>

    </div>

    </div>
  );
}

const LearnByDoing = () => {
  const breakpoint = 740;
  const { width } = useWindowDimensions()
  const mobile = width < breakpoint;
  return (
  <Row>
    <Col lg={{span:16,offset:4}} md={{span:24}}>
      <h1 className="intro-title" style={{fontSize:mobile ? 40 : 54}}>
        Aprende haciendo
      </h1>

      <p style={{fontSize:18}} >
        El aprendizaje es un proceso difícil que requiere no solo nuestra atención si no que también requiere interacción.
        Nuestros cursos tienen una alta componente interactiva para involucrarnos en el aprendizaje. 
          <span style={{display:'block',fontSize:36, color:'#96bf0e'}} >
            <b>Aprendizaje</b> estimulante, cómodo e intuitivo.
          </span>
      </p>
    </Col>
  </Row>
  );
};


/*
const Interface = (props) => {
  const breakpoint = 740;
  const { width } = useWindowDimensions()
  const mobile = width < breakpoint;
  return (
    <React.Fragment>
      <Row>
        <Col lg={{span:16,offset:4}} md={{span:24}}>
      <h1 className="intro-title" style={{fontSize:mobile ? 40 : 54}}>
        Interfaz amigable
      </h1>
      <div>
        <p style={{fontSize:18}} >
          Usamos las últimas tecnologías para crear interfaces rápidas, responsivas e intuitivas.
        </p>

        <p style={{fontSize:22, color:'#2178b1'}} >
          "El diseño no es solo la apariencia, el diseño es cómo funciona”."
        </p>
        <p style={{fontSize:18}} >
          Nos preocupamos de que nuestros cursos sean intuitivos y funcionales.
        </p>
        <div>
          <p style={{fontSize:36, color:'#96bf0e'}} >
            <b>Aprendizaje</b> estimulante, cómodo e intuitivo.
          </p>
          </div>
      </div>
      </Col></Row>
    </React.Fragment>
  );
}
*/

const Desarrollo = () => {
  const breakpoint = 740;
  const { width } = useWindowDimensions()
  const mobile = width < breakpoint;
  return (<React.Fragment>
      <Row>
        <Col lg={{span:16,offset:4}} md={{span:24}}>
      <h1 className="intro-title" style={{fontSize:mobile ? 40 : 54}}>
            Desarrollo de programas personalizados
          </h1>
        </Col>
      </Row>
      <Row>
        <Col lg={{span:12, offset:2}} md={24}>
          <div className="step-container" style={{transform:mobile ? 'scale(0.8)' : 'scale(1)'}}>
            <div style={{marginLeft:180}}>
              <span className="step-number">3</span>
              <span className="step-text">
                Programas a medida
              </span>
            </div>

            <div style={{marginLeft:90}}>
              <span className="step-number">2</span>
              <span className="step-text">
                Procesos de inducción
              </span>
            </div>

            <div>
              <span className="step-number">1</span>
              <span className="step-text">
                Precontrato
              </span>
            </div>
          </div>

        </Col>
        <Col lg={{span:8}} md={24}>
          <img src={stairs} alt="escalera"  className="step-img"/>
        </Col>
      </Row>
    </React.Fragment>
  );
};

const Asesoramos = () => {
  const breakpoint = 740;
  const { width } = useWindowDimensions()
  const mobile = width < breakpoint;
  return (
    <div style={{marginTop:48, color:'white',backgroundColor:'rgba(0,0,0,0.4)'}}>
        <Parallax
          bgImage={planning}
          className="planning-parallax"
          bgImageAlt=""
          strength={600}
          renderLayer={percentage => (
            <div
              style={{
                background: `rgba(0, 0, 0, ${percentage * 1})`,
                left: 0,
                position: 'absolute',
                top: 0,
                width:'100%',
                height:'100%',
              }}>
              <p style={{
                left: mobile ? 0 : '20%',
                position: 'absolute',
                top: mobile ? '40%' : '50%',
                width: mobile ? '100%' : '60%',
                padding: mobile ? 20 : 0,
                lineHeight:'40px',
                fontSize:22
                }}>
              Trabajamos junto al área de Desarrollo Organizacional de su empresa un proceso riguroso que incluye: análisis, propuesta, desarrollo y evaluación de los programas de educación corporativa.
            </p>
          </div>
          )}
        >

      <Row style={{marginTop:mobile ? 40 : 72}}>
        <Col lg={{span:16,offset:4}} md={{span:24}}>
          <h1 className="intro-title" style={{fontSize:mobile ? 40 : 54,color:'white'}}>
            Asesoramos para Crear Valor
          </h1>
        </Col>
      </Row>

      <div style={{height:mobile ? '50vh' : '40vh'}}/>
      </Parallax>

    </div>
  );
};


