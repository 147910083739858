import React from 'react';
import { useWindowDimensions } from '../Utility/WindowDimensionsProvider';
import blendedBg from './blendedBg.png';
import blendedFg from './blendedFg.png';
import logo from './campuschile-white-logo.png';
import { Row, Col } from 'antd';
import { Parallax } from 'react-parallax';

const Blended = (props) => {
  const breakpoint = 740;
  const { width } = useWindowDimensions()
  const mobile = width < breakpoint;
  return (
    <Row>
      <Col lg={{span:24,offset:0}} md={{span:24}}>
        <Parallax
          bgImage={blendedBg}
          className="blended-parallax"
          bgImageAlt="blended"
          strength={400}
        >
          <Row>
            <Col lg={{span:20,offset:2}} md={{span:24}}>
              <div style={{
                position:'absolute',
                top:mobile ? '10%' : '20%',
                left:'0',
                transformOrigin:0,
                transform:mobile ? 'scale(0.5)' : 'scale(1)',
                }}>
                <div style={{
                  backgroundColor:'#96bf0e',
                  display:'inline-block',
                  width:40,
                  height:92,
                  marginRight:10,
                  transform:'translateY(2px)',
                }}></div>
                <div style={{display:'inline-block'}}>
                  <div style={{
                    width:300,
                    color: 'white',
                    fontWeight:'600', 
                    borderBottom:'2px dashed #96bf0e',
                    fontSize:40,
                    lineHeight:'59px',
                    }}>MODALIDAD DE</div>
                  <div style={{
                    width:300, 
                    color: 'white',
                    fontSize:31,
                    paddingTop:6,
                    lineHeight:'31px',
                    fontWeight:'600',
                    }}>NUESTROS CURSOS</div>
                </div>
              </div>
              <img src={blendedFg} alt="" 
                style={{width:mobile ? '80%' : 800,marginTop:'20vh',marginBottom:'20vh'}}/>
              <img src={logo} alt="" 
                style={{
                height:mobile ? 40 : 70,position:'absolute',top:mobile ? 80 : 150,right:0}}/>
            </Col>
          </Row>
        </Parallax>
      </Col>
    </Row>

    )
}
export default Blended;
