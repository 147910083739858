import React, { useState, useEffect } from 'react';
import { Button, Tooltip } from 'antd';

const Return = (props) => {

  const [showArrow, setShowArrow] = useState(false)

  const handleScrollEvent = (e) => {
    let h = document.documentElement;
    setShowArrow(h['scrollTop'] > 900)
  }
     
  useEffect(() => {
    window.addEventListener('scroll', handleScrollEvent)
    return () => {
      window.removeEventListener('scroll', handleScrollEvent)
    }
  },[]);


  const scrollToSmoothly = (pos, time) => {
    var currentPos = window.scrollY||window.screenTop;
    var i = currentPos;
    const h = currentPos - pos;

    const dt = 10
    const dh = dt*h / time
    var x = setInterval(() => {
      window.scrollTo(0, i);
      i -= dh;
      if(i<=pos){clearInterval(x);}
    }, dt);
  }

  const handleScrollUp = () => {
		scrollToSmoothly(0,400)
	}

  return (
    <div style={{marginTop:48,marginBottom:48}}>
			<Tooltip title="Subir">
        <Button 
          style={{
            visibile:showArrow ? 'inherit' : 'hidden',
            pointerEvents:showArrow ? 'auto' : 'none',
            opacity:showArrow ? 1 : 0,
            transition:'all 0.4s ease-in-out',
            position:'fixed',
            right:30,
            bottom:30,
            width:60,
            height:60,
            fontSize:30,
          }}
          onClick={handleScrollUp} 
          type="primary" 
          shape="circle" 
          icon="arrow-up"
        />
			</Tooltip>
    </div>
  );
}

export default Return

