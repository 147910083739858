import React, { useEffect } from 'react';
import Carousel from 'nuka-carousel';
import { Button, Row, Col } from 'antd';
// import logo_campuschile from './logo_azul.png';
import demo1 from './demo1.png';
import demo2 from './demo2.jpg';
import demo3 from './demo3.jpg';
import demo4 from './demo4.jpg';
import demo5 from './demo5.png';
import demo6 from './demo6.jpg';
import { useWindowDimensions } from '../Utility/WindowDimensionsProvider';

const Desarrollo = () => {
  const breakpoint = 740;
  const { width } = useWindowDimensions()
  const mobile = width < breakpoint;


  useEffect(() => {
    setTimeout(() => window.dispatchEvent(new Event("resize")), 200)
  }, []);

  return (
    <div >
      <div style={{marginTop:48}}>
        <br/>
        <div className="intro-title" style={{...(mobile ? {fontSize:34} : {})}}>
          Desarrollo de cursos a la medida
        </div>
        <br/>
        <Row>
          <Col lg={{span:16,offset:4}} md={24} style={{background:'black'}}>
            <Carousel
              renderCenterLeftControls={({ previousSlide }) => (
              <Button 
                shape="circle" 
                type="primary" 
                icon="left"  
                onClick={previousSlide} 
                style={{transform:mobile ? 'tanslateX(40px)' : 'translateX(-40px)'}}
              ></Button>
              )}
              renderCenterRightControls={({ nextSlide }) => (
              <Button 
                shape="circle" 
                type="primary" 
                icon="right" 
                onClick={nextSlide} 
                style={{transform:mobile ? 'tanslateX(-40px)' : 'translateX(40px)'}}
              ></Button>
              )}
            >
              <img className="demo" src={demo1} alt="demo 1" />
              <img className="demo" src={demo2} alt="demo 2" />
              <img className="demo" src={demo3} alt="demo 3" />
              <img className="demo" src={demo4} alt="demo 4" />
              <img className="demo" src={demo5} alt="demo 5" />
              <img className="demo" src={demo6} alt="demo 6" />
             </Carousel>
          </Col>
        </Row>
      </div>
  </div>
  )
}

export default Desarrollo;
        // <img src={logo_campuschile} alt="logo campuschile" style={{height:50}} />
        // <div style={{height:46,width:'70%',float:'left',marginLeft:'20px',marginTop:'2px',display:'block',backgroundColor:'#2178b1'}}></div>
