import "core-js/stable";
import "regenerator-runtime/runtime";
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import moment from 'moment';
import 'moment/locale/es';

import React from 'react';
import ReactDOM from 'react-dom';

moment.locale('es');

ReactDOM.render(
  <App />,
  document.getElementById('campuschile')
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
