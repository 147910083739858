
import React, { useState } from 'react';
import {
  Card, 
  message,
  Form, 
  Input, 
  Button,
  Row,
  Col
} from 'antd';
import contactSplash from './contact-splash.jpg';
import { Parallax } from 'react-parallax';

const { TextArea } = Input;

const Contact = ({form}) => {

  const [showForm, setShowForm] = useState(true)
  const [blockBtn, setBlockBtn] = useState(false)
  const { getFieldDecorator } = form;

  const onSubmit = (e) => {
    setBlockBtn(true)
    e.preventDefault()
    /* get from form */
    const body = form.getFieldValue('body');
    const email = form.getFieldValue('email');
    const subject = form.getFieldValue('subject');
    const name = form.getFieldValue('name');

    form.validateFields((error, values) => {
      if (!error) {
        fetch('https://ts72g1rk77.execute-api.us-east-1.amazonaws.com/test/contact-email', {
          method: 'POST',
          mode: 'cors',
          cache: 'no-cache',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({body, name, email, subject}),
        }).then((response) => {
          console.log(response.status)
          // const success = response.status == 200
          // const errorMessage = 'Hubo un error al enviar este mensaje'
          // success ? message.success(successMessage) : message.error(errorMessage)
          const successMessage = 'Mensaje enviado con éxito.'
          message.success(successMessage)
          setShowForm(false);
          setBlockBtn(false)
        }).catch(() => {
          // fix this. lambda is working but response isnt 
          const successMessage = 'Mensaje enviado con éxito.'
          message.success(successMessage)
          setShowForm(false);
          setBlockBtn(false)
        });
      }
    });

  }

      // bgImageAlt="manos"
      // strength={500}
      // src={contactSplash}
  return <div>
    <div 
      className="contact-parallax"
    >
      <div>
      <h1 style={{margin:0,fontSize:44, fontWeight:400, lineHeight:'120px',color:'white'}}>Formulario de contacto</h1>
      <Row><Col lg={{span:14,offset:5}} md={{span:24}}>
        {!showForm && <div style={{height:'20vh'}}/>}
      <Card style={{backgroundColor:'rgba(255,255,255,0.92)',textAlign:'left'}}>
        {showForm ? 
          <Form >
            <div style={{position:'relative'}}>
              <Row>
                <Col lg={11} md={24}>
              <h3>Ingrese su nombre:</h3>
              <Form.Item>
                {getFieldDecorator('name', {rules: [{required:true, message:'Ingrese su nombre'}]})(
                <Input placeholder="Nombre" />
                )}
              </Form.Item>
              </Col>
              <Col lg={{span:12,offset:1}} md={24}>
                <h3>Ingrese su correo electrónico:</h3>
                <Form.Item>
                  {getFieldDecorator('email', {rules: [{required:true, message:'Ingrese su correo'}]})(
                  <Input placeholder="usuario@example.com" />
                  )}
                </Form.Item>
              </Col>
              </Row>
              <Row>
              <Col  span={24}>
                <h3>Asunto:</h3>
                <Form.Item>
                  {getFieldDecorator('subject', {rules: [{required:true, message:'Ingrese un asunto'}]})(
                  <Input placeholder="Asunto" />
                  )}
                </Form.Item>
              </Col>
              </Row>
            </div>
            <Form.Item>
              <h3>Ingrese su mensaje:</h3>
              {getFieldDecorator('body', {rules: [{required:true, message:'Ingrese el mensaje'}]})(
              <TextArea placeholder="Ingrese su mensaje" rows={4}/>
              )}
            </Form.Item>

            <Form.Item className="login-form-button-container">
              <Button disabled={blockBtn} onClick={onSubmit} type='primary' htmlType='submit' style={{width:'100%'}}>
                Enviar
              </Button>
            </Form.Item>
          </Form>
        : 
          <div style={{textAlign:'center'}}>
            <span style={{fontSize:20}}>Mensaje enviado</span>
            <br/>
            <div style={{color:'#96bf0e',fontSize:16}} onClick={() => setShowForm(true)}>Enviar otro mensaje</div>
          </div>
        }
      </Card>
      {!showForm && <div style={{height:'30vh'}}/>}
    </Col>
    </Row>
  </div>
    <br/><br/>
    <br/><br/>
  </div>
  </div>
}
          // <div style={{position:'relative'}}>
            // {getFieldDecorator('emailCopy', {rules: [{required:true, message:'Ingrese el copy'}]})(
            // <Checkbox disabled>Enviar una copia a mi correo</Checkbox>
            // )}
            // <br/><br/>
          // </div>

export default Form.create({name: 'contact'})(Contact)
