import React from 'react';
import howWeLearnGraph from './HowWeLearnGraph.png';
import { Row, Col } from 'antd';

const HowWeLearn = () => {
  return (
    <Row style={{paddingTop:48}}>
      <Col lg={{span:18,offset:3}} md={{span:22,offset:1}} >
      <img 
        width="100%"
        src={howWeLearnGraph} 
        alt="cómo aprendemos los adultos"/>
      </Col>
    </Row>
  );
}

export default HowWeLearn;
