import React from 'react';
import { Card, Button, Form, Checkbox, Input, Icon } from 'antd';
import './MoodleForm.css';

const MoodleForm = ({form}) => {

  const { getFieldDecorator } = form
  // const [isEnglish,setIsEnglish] = useState(false)
  const isEnglish = false;

  const moodleUrl = "https://curso.campuschile.cl/login/index.php"
  const moodleInglesUrl = "https://ingles.campuschile.cl/login/index.php"

  return (
    <Card className="login-form-container">
      <h2 style={{width:250}}>
        {isEnglish ? 
        "Ingresa a tus cursos inglés CampusChile"
        : "Ingresa a tus cursos de CampusChile"
        }
      </h2>

      <Form onSubmit={() => {}} className="login-form" action={isEnglish ? moodleInglesUrl : moodleUrl} method="post">
        <Form.Item>
          {getFieldDecorator('username', {
            rules: [{ required:true, message: 'Ingrese su usuario!' }],
          })(
          <Input placeholder="Usuario" name="username"
            prefix={<Icon type="user" style={{color:'rgba(0,0,0,.25)'}}/>}/>)}
        </Form.Item>


        <input id="anchor" type="hidden" name="anchor" value="" />

        <Form.Item>
          {getFieldDecorator('password', {
            rules: [{ required:true, message: 'Ingrese su contraseña!' }],
          })(
          <Input placeholder="Contraseña" type="password" name="password"
            prefix={<Icon type="lock" style={{color:'rgba(0,0,0,.25)'}}/>}/>)}
        </Form.Item>

        <div className="login-form-remember">
          {getFieldDecorator('rememberusername', { valuePropName: 'checked', initialValue: true, })(
            <Checkbox name="rememberusername" value="1">Recordar sesión</Checkbox>
          )}
        </div>

        <Form.Item style={{marginBottom:0}}>
          <div>
            <Button type="primary" htmlType="submit" className="login-form-button" 
              style={
                isEnglish ? {backgroundColor:'#2178b1'} : {}
              }>
              <Icon type="login" style={{verticalAlign:'middle'}}/>
              Iniciar sesión
            </Button>
          </div>
        </Form.Item>
      </Form>
    </Card>
  )
}
        // <p style={{cursor:'pointer'}} onClick={() => {setIsEnglish(true)}}>
         // ¿Quieres ingresar a cursos de inglés?
        // </p>
        // <a className="login-form-forgot" href="">Forgot password</a>

export default Form.create({name: ''})(MoodleForm)

/* 
  <input type="checkbox" name="rememberusername" id="rememberusername" value="1"  />
  <label for="rememberusername">Recordar nombre de usuario</label>
*/
