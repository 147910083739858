import React from 'react';
import { Parallax } from 'react-parallax';
import { Row, Col } from 'antd';
import logo_campuschile_y_ofimatica from './logo_campuschile_y_ofimatica.png';
import logo_cursos from './logo_cursos.png';
import office_logos from './office_logos.png';
import ofimatica from './ofimatica.jpg';
import triptico from './triptico2.jpg';
import titulo_gestion from './titulo_gestion.png';
import fondo_gestion from './fondo_gestion.jpg';
import logo_tecnologia from './logo_tecnologia.png';
import logos_google from './logos_google.png';
import tecnologia from './tecnologia.jpg';
import { useWindowDimensions } from '../Utility/WindowDimensionsProvider';

import './courses.css';

const Courses = () => {
  const breakpoint = 740;
  const { width } = useWindowDimensions()
  const mobile = width < breakpoint;
  return (
    <React.Fragment>
      <Row style={{marginTop:48}}>
        <Col span={24} offset={0}>
          <Parallax
            bgImage={ofimatica}
            className="blended-parallax"
            bgImageAlt="blended"
            strength={200}
          >
              {!mobile && <img src={logo_cursos} alt="texto cursos campuschile" style={{height:70,position:'absolute',top:45,left:0}}/>}
              <img src={office_logos} alt="logos programas office" 
                style={
                mobile ? {
                    width:'80%',
                    position:'absolute',
                    top:150,
                    left:'10%'
                  } : {
                    height:300,
                    position:'absolute',
                    top:150,
                    left:40
                }}
              />
              <img src={logo_campuschile_y_ofimatica} alt="ofimática" style={{maxWidth:'100%',height:70,position:'absolute',top:35,right:0}}/>
              <div style={{height:mobile ? '90vh' : '70vh'}}></div>
          </Parallax>
        </Col>
      </Row>
      <Row style={{marginTop:48}}>
        <Col span={24} offset={0}>
          <Parallax
            bgImage={tecnologia}
            className="blended-parallax"
            bgImageAlt="blended"
            strength={200}
          >
              {!mobile && <img src={logos_google} alt="logos programas office" style={{height:120,position:'absolute',top:150,left:40}}/>}
              <img src={logo_tecnologia} alt="ofimática" style={{maxWidth:'100%',height:70,position:'absolute',top:35,right:0}}/>
              {!mobile && <img src={logo_cursos} alt="texto cursos campuschile" style={{height:70,position:'absolute',top:45,left:0}}/>}
              <ul className={"catalog-list" + (mobile ? " mobile" : "")} style={{position:'absolute',top:mobile ? 250 : 330,left:0}}>
                <li>Project Management.</li>
                <li>Cloud Computing.</li>
                <li>Digitación y Mecanografía.</li>
                <li>Gestión de Redes Sociales.</li>
                <li>Seguridad en Redes y sistemas informáticos.</li>
                <li>Herramientas de Google.</li>
              </ul>
              <div style={{height:'90vh'}}></div>
          </Parallax>
        </Col>
      </Row>
      <Row style={{marginTop:48}}>
        <Col span={24} offset={0}>
          <Parallax
            bgImage={fondo_gestion}
            className="blended-parallax"
            bgImageAlt="blended"
            strength={200}
          >
              <img src={titulo_gestion} alt="ofimática" style={{maxWidth:'100%',height:60,position:'absolute',top:35,right:0}}/>
              {!mobile && <img src={logo_cursos} alt="texto cursos campuschile" style={{height:70,position:'absolute',top:45,left:0}}/>}
              <ul className={"catalog-list" + (mobile ? " mobile" : "")} style={{position:'absolute',top:130,left:0}}>
                <li>  Gestión Financiera para No Especialistas</li>
                <li>Control y Gestión de Costos.</li>
                <li>Aplicaciones de Técnicas de Venta.</li>
                <li>Atención y Servicio al Cliente.</li>
                <li>Gestión de Comercio Exterior.</li>
                <li>Mecanografía.</li>
                <li>Venta online.</li>
                <li>Dirección Comercial y Marketing.</li>
                <li>Estrategia y Comunicación Empresarial.</li>
                <li>Gestión de Cobranzas y Reclamos.</li>
                <li>Gestión de E-Business Empresarial.</li>
                <li>Gestión Económica y Financiera.</li>
                <li>Innovación y Creatividad Empresarial.</li>
                <li>Responsabilidad Social Corporativa.</li>
                <li>Sistemas de Gestión: Implementación de <br/>Gestión Medioambiental ISO 14001.</li>
                <li>SEM Manager.</li>
                <li>Legislación Laboral.</li>
                <li>Herramientas para una Inclusión Laboral Efectiva.</li>
              </ul>
              <div style={{height:mobile ? '130vh' : '110vh'}}></div>
          </Parallax>
        </Col>
      </Row>
      <Row style={{marginTop:48}}>
        <Col span={24} offset={0}>
          <img src={triptico} alt="" style={{width:'100%',marginBottom:48}}/>
        </Col>
      </Row>
      <Row>
        {mobile && <Col className="catalog-course-title-2" md={24}>Habilidades Blandas</Col>}
        <Col lg={{span:8,offset:0}} md={24}>
          <ul className="catalog-list" >
            <li>Liderazgo de equipos</li>
            <li>Oratoria y expresión oral</li>
            <li>Gestión de equipos de trabajo</li>
            <li>Planificación y gestión del tiempo</li>
            <li>Negociación y comunicación efectiva</li>
            <li>Calidad de Vida </li>
          </ul>
        </Col>
        {mobile && <Col className="catalog-course-title-2" md={24}>Inglés</Col>}
        <Col lg={{span:8,offset:0}} md={24}>
          <ul className="catalog-list" >
            <li>Inglés A1</li>
            <li>Inglés A2</li>
            <li>Inglés B1</li>
            <li>Inglés B2</li>
            <li>Inglés C1</li>
          </ul>
        </Col>
        {mobile && <Col className="catalog-course-title-2" md={24}>Otros</Col>}
        <Col lg={{span:8,offset:0}} md={24}>
          <ul className="catalog-list" >
            <li>Emprender un negocio</li>
            <li>Nuevos mercados para PYMES</li>
            <li>Programa de dirección empresarial para emprendedores</li>
            <li>Grúa Horquilla</li>
          </ul>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default Courses;

            // <div style={{display:'inline-block'}}>
              // <div style={{
                // width:300,
                // color: 'white',
                // fontWeight:'600', 
                // borderBottom:'2px dashed #96bf0e',
                // fontSize:40,
                // lineHeight:'59px',
                // }}>CURSOS</div>
              // <div style={{
                // width:300, 
                // color: '#2178b1',
                // fontSize:31,
                // paddingTop:6,
                // lineHeight:'31px',
                // fontWeight:'600',
                // }}>CAMPUSCHILE</div>
            // </div>
